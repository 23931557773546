<template>
  <form ref="organizationForm" @submit.prevent="submitHandler">
    <!-- Formularinhalte -->
    <CRow>
      <CCol sm="6">
        <CInput
          label="Name"
          v-model="dataName"
          required
          placeholder="Enter the name of the organization"
        />
      </CCol>
      <CCol>
        <CInput
          label="Stripe Customer ID"
          v-model="dataStripeCustomerId"
          required
          placeholder="Enter the ID of the Customer in Stripe"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
          label="Contact Phone"
          v-model="dataContactPhone"
          required
        />
      </CCol>
      <CCol sm="6">
        <CSelect
          label="Status"
          :value="dataStatus"
          @update:value="dataStatus = $event"
          required
          :options="['ACTIVE', 'INACTIVE', 'PENDING', 'BANNED']"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
          label="Contact Address"
          v-model="dataContactAddress"
          required
        />
      </CCol>
      <CCol sm="6">
        <ImageUploadField
          v-model="dataLogoUrl"
          :horizontal="false"
          label="Logo"
        />
      </CCol>
    </CRow>
    <template v-if="isCreate">
      <hr/>
      <CRow>
        <CCol sm="6">
          <CInput
            label="First subscription: Name"
            v-model="dataSubscriptionName"
            required
          />
        </CCol>
        <CCol sm="6">
          <CSelect
            label="First subscription: Plan"
            :value="dataSubscriptionPlan"
            @update:value="dataSubscriptionPlan = $event"
            required
            :options="Object.keys($commons.REALM_PLANS)"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="4">
          <CInput
            label="First subscription: License"
            v-model="dataSubscriptionLicense"
            required
          />
        </CCol>
        <CCol sm="4">
          <CInput
            label="First subscription: Stripe Subscription ID"
            v-model="dataStripeSubscriptionId"
            placeholder="Enter here the Subscription ID from Stripe"
            required
          />
        </CCol>
        <CCol sm="4">
          <CInput
            label="First subscription: Allowed domains"
            v-model="dataSubscriptionAllowedDomains"
            required
          />
        </CCol>
      </CRow>
      <hr/>
      <CRow>
        <CCol sm="6">
          <CInput
            label="E-Mail"
            v-model="dataEmail"
            required
            description="E-Mail of the Organisation Admin (will be Username)"
          />
        </CCol>
        <CCol sm="6">
          <CInput
            label="Password"
            v-model="dataUserPassword"
            :disabled="dataSendPasswordEmail"
            required
          />
          <BooleanCheckbox
            v-if="isCreate"
            style="text-align: left"
            label=""
            name="sendPasswordEmail"
            text="Send an email for password creation to the user?"
            v-model="dataSendPasswordEmail"
          />
        </CCol>
      </CRow>
    </template>
    <CRow>
      <CCol>
        <CButton v-if="!processing" type="submit" color="success">
          {{ btnText }}
        </CButton>
        <CButton v-else disabled color="primary">
          Processing...
        </CButton>
      </CCol>
    </CRow>
  </form>
</template>
  
  <script>
  import axios from 'axios';
  import Checkbox from "@/components/Checkbox";
  import BooleanCheckbox from "@/components/BooleanCheckbox";
  import ImageUploadField from "@/components/ImageUploadField";
  
  export default {
    name: 'OrganizationForm',
    components: { ImageUploadField, BooleanCheckbox, Checkbox },
    props: {
      name: { type: String, default: '' },
      contactPhone: { type: String, default: '' },
      contactAddress: { type: String, default: '' },
      logoUrl: { type: String, default: '' },
      status: {
        type: String,
        default: 'ACTIVE',
        validator: value => ['ACTIVE', 'INACTIVE', 'PENDING', 'BANNED'].includes(value),
      },
      stripeCustomerId: String,
      btnText: { type: String, required: true },
      isCreate: { type: Boolean, required: false, default: true },
      processing: Boolean,
    },
    data() {
      return {
        dataName: this.name,
        dataStripeCustomerId: this.stripeCustomerId,
        dataContactPhone: this.contactPhone,
        dataContactAddress: this.contactAddress,
        dataStatus: this.status,
        dataSubscriptionLicense: null,
        dataSubscriptionAllowedDomains: null,
        dataSubscriptionName: null,
        dataSubscriptionPlan: 'TRIAL',
        dataStripeSubscriptionId: null,
        dataEmail: null,
        dataUserPassword: null,
        dataSendPasswordEmail: false,
        dataLogoUrl: this.logoUrl,
      };
    },
    methods: {
    async submitHandler(event) {
      event.preventDefault(); // Verhindert das Standardformularverhalten

      if (!this.$refs.organizationForm.checkValidity()) {
        this.$toaster.makeToast('Fehler!', 'Bitte füllen Sie alle erforderlichen Felder aus.');
        return;
      }

      if (this.processing) {
        return; // Verhindert doppelte Anfragen
      }

      this.processing = true; // Setzt den Verarbeitungsstatus auf true

      const formData = {
        name: this.dataName,
        stripeCustomerId: this.dataStripeCustomerId,
        contactPhone: this.dataContactPhone,
        contactAddress: this.dataContactAddress,
        status: this.dataStatus,
        subscriptionLicense: this.dataSubscriptionLicense,
        subscriptionAllowedDomains: this.dataSubscriptionAllowedDomains,
        subscriptionPlan: this.dataSubscriptionPlan,
        subscriptionName: this.dataSubscriptionName,
        stripeSubscriptionId: this.dataStripeSubscriptionId,
        email: this.dataEmail,
        userPassword: this.isCreate ? this.dataUserPassword : undefined, // Nur beim Erstellen das Passwort senden
        sendPasswordEmail: this.isCreate ? this.dataSendPasswordEmail : undefined,
        logoUrl: this.dataLogoUrl,
      };

      try {
        const response = await axios.put(this.isCreate ? '/organization' : `/organization/${this.$route.params.id}`, formData);
        if (response.status === 200 && response.data) {
          if (this.isCreate) {
            this.$toaster.makeToast('Erfolg!', 'Organisation wurde erfolgreich erstellt.');
          } else {
            this.$toaster.makeToast('Erfolg!', 'Organisation erfolgreich bearbeitet!');
          }
          this.$emit('submit', formData);
        } else {
          throw new Error('Fehler bei der Erstellung oder Bearbeitung der Organisation');
        }
      } catch (error) {
        console.error('Fehler beim Erstellen oder Bearbeiten der Organisation:', error);
        this.$toaster.makeToast('Fehler!', 'Es gab ein Problem bei der Erstellung oder Bearbeitung der Organisation. Bitte versuchen Sie es erneut.');
      } finally {
        this.processing = false; // Setzt den Verarbeitungsstatus zurück
      }
    }
  }
};
  </script>
  